.skill{
  background-color: rgba(255, 207, 83, 0.91);
  color:rgba(255, 255, 255, 0.63);
  border-radius: 6px;
  padding:6px;
  font-size: 20px;
  font-weight: 900;
  margin-right:16px;
  margin-bottom: 10px;
  box-shadow: 6px 4px 0 0 coral;
}