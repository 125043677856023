.App {
    text-align: center;
    height: 100dvh;
    color: #fff;
    font-size: calc(10px + 2vmin);
}


.profile {
    width: 150px;
    height: 150px;
    background-color: #282c34;
    border-radius: 75px;
    border: solid 1px whitesmoke;
    background-image: url('static/avatr.jpg');
    background-size: cover;
}

.splide__slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

.icon {
    margin: 5px
}

.card {
    width: 75vw;
    /*height:80vh;
    /*background:white;*/
    border-radius: 6px;
    color: rgba(255, 255, 255, 0.863)
}

/* 大标题 */
.card-title {
    font-size: 40px;
    font-weight: 900;
}

.card-content {
    /*margin-left: 40px;*/
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 40px;
}

.weixin {
    display: inline-flex;
    position: relative;
    cursor: pointer;
}

.weixin + p {
    display: none;
    position: absolute;
}

.weixin:hover + p {
    display: block;
    position: absolute;
    margin: 0;
    top: 30px;
    padding: 2px 5px;
    text-align: center;
    background-color: white;
    color: black;
    border-radius: 3px;

}